<template>
  <div>
    <!-- 大於 sm -->
    <el-carousel :interval="4000" height="300px" class="d-none d-sm-block">
      <el-carousel-item v-for="(college, index) in collegesMd" :key="index">
        <div class="d-flex">
          <div style="width:25%; text-align:center" class="pr-1">
            <el-image :src="college.src1"></el-image>
            <span>{{ college.name1 }}</span>
          </div>
          <div style="width:25%; text-align:center" class="pl-1 pr-1">
            <el-image :src="college.src2"></el-image>
            <span>{{ college.name2 }}</span>
          </div>
          <div style="width:25%; text-align:center" class="pl-1 pr-1">
            <el-image :src="college.src3"></el-image>
            <span>{{ college.name3 }}</span>
          </div>
          <div style="width:25%; text-align:center" class="pl-1">
            <el-image :src="college.src4"></el-image>
            <span>{{ college.name4 }}</span>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <!-- 小於 sm -->
    <el-carousel :interval="3000" height="400px" class="d-block d-sm-none">
      <el-carousel-item v-for="(college, index) in colleges" :key="index">
        <div>
          <div style="width:100%; text-align:center" class="pr-1 pl-1 d-block">
            <div>
              <el-image :src="college.src"></el-image>
            </div>
            <div>
              <span>{{ college.name }}</span>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collegesMd: [
        // 1
        {
          src1: require("@/assets/colleges/harvard.jpg"),
          name1: "Harvard",
          src2: require("@/assets/colleges/mit.jpg"),
          name2: "MIT",
          src3: require("@/assets/colleges/pennstate.jpg"),
          name3: "Penn State",
          src4: require("@/assets/colleges/columbia.jpg"),
          name4: "Columbia"
        },
        // 2
        {
          src1: require("@/assets/colleges/cornell.jpg"),
          name1: "Cornell",
          src2: require("@/assets/colleges/johnshopkins.jpg"),
          name2: "Johns Hopkins",
          src3: require("@/assets/colleges/wellesley.jpg"),
          name3: "Wellesley",
          src4: require("@/assets/colleges/carnegiemellon.jpg"),
          name4: "Carnegie Mellon"
        },
        // 3
        {
          src1: require("@/assets/colleges/usc.jpg"),
          name1: "USC",
          src2: require("@/assets/colleges/wakeforestuniversity.jpg"),
          name2: "Wake Forest University",
          src3: require("@/assets/colleges/tufts.jpg"),
          name3: "Tufts",
          src4: require("@/assets/colleges/nyu.jpg"),
          name4: "NYU"
        },
        // 4
        {
          src1: require("@/assets/colleges/bu.jpg"),
          name1: "Boston University",
          src2: require("@/assets/colleges/ucd.jpeg"),
          name2: "UCD",
          src3: require("@/assets/colleges/ucsd.jpg"),
          name3: "UCSD",
          src4: require("@/assets/colleges/ucsb.jpg"),
          name4: "UCSB"
        },
        // 5
        {
          src1: require("@/assets/colleges/uci.jpg"),
          name1: "UCI",
          src2: require("@/assets/colleges/ucr.jpg"),
          name2: "UCR",
          src3: require("@/assets/colleges/michiganstateuniversity.jpg"),
          name3: "Michigan State University",
          src4: require("@/assets/colleges/universityofwashingtonseattle.jpg"),
          name4: "University of Washington"
        },
        // 6
        {
          src1: require("@/assets/colleges/fordham.jpg"),
          name1: "Fordham",
          src2: require("@/assets/colleges/universityofalabama.jpg"),
          name2: "University of Alabama",
          src3: require("@/assets/colleges/universityofsandiego.jpg"),
          name3: "University of San Diego",
          src4: require("@/assets/colleges/georgiatech.jpg"),
          name4: "Georgia Tech"
        },
        // 7
        {
          src1: require("@/assets/colleges/utaustin.jpg"),
          name1: "UT Austin",
          src2: require("@/assets/colleges/stmaryscollege.jpg"),
          name2: "St. Mary's College",
          src3: require("@/assets/colleges/usf.jpg"),
          name3: "University of San Francisco",
          src4: require("@/assets/colleges/universityofthepacific.jpg"),
          name4: "University of the Pacific"
        },
        // 8
        {
          src1: require("@/assets/colleges/csulb.jpg"),
          name1: "CSULB",
          src2: require("@/assets/colleges/csuf.jpg"),
          name2: "CSUF",
          src3: require("@/assets/colleges/universityofhouston.jpg"),
          name3: "University of Houston",
          src4: require("@/assets/colleges/texasam.jpg"),
          name4: "Texas A&M"
        }
      ],
      colleges: [
        // 1
        {
          src: require("@/assets/colleges/harvard.jpg"),
          name: "Harvard"
        },
        // 2
        {
          src: require("@/assets/colleges/mit.jpg"),
          name: "MIT"
        },
        // 3
        {
          src: require("@/assets/colleges/pennstate.jpg"),
          name: "Penn State"
        },
        // 4
        {
          src: require("@/assets/colleges/columbia.jpg"),
          name: "Columbia"
        },
        // 5
        {
          src: require("@/assets/colleges/cornell.jpg"),
          name: "Cornell"
        },
        // 6
        {
          src: require("@/assets/colleges/johnshopkins.jpg"),
          name: "Johns Hopkins"
        },
        // 7
        {
          src: require("@/assets/colleges/wellesley.jpg"),
          name: "Wellesley"
        },
        // 8
        {
          src: require("@/assets/colleges/carnegiemellon.jpg"),
          name: "Carnegie Mellon"
        },
        // 9
        {
          src: require("@/assets/colleges/usc.jpg"),
          name: "USC"
        },
        // 10
        {
          src: require("@/assets/colleges/wakeforestuniversity.jpg"),
          name: "Wake Forest University"
        },
        // 11
        {
          src: require("@/assets/colleges/tufts.jpg"),
          name: "Tufts"
        },
        // 12
        {
          src: require("@/assets/colleges/nyu.jpg"),
          name: "NYU"
        },
        // 13
        {
          src: require("@/assets/colleges/bu.jpg"),
          name: "Boston University"
        },
        // 14
        {
          src: require("@/assets/colleges/ucd.jpeg"),
          name: "UCD"
        },
        // 15
        {
          src: require("@/assets/colleges/ucsd.jpg"),
          name: "UCSD"
        },
        // 16
        {
          src: require("@/assets/colleges/ucsb.jpg"),
          name: "UCSB"
        },
        // 17
        {
          src: require("@/assets/colleges/uci.jpg"),
          name: "UCI"
        },
        // 18
        {
          src: require("@/assets/colleges/ucr.jpg"),
          name: "UCR"
        },
        // 19
        {
          src: require("@/assets/colleges/michiganstateuniversity.jpg"),
          name: "Michigan State University"
        },
        // 20
        {
          src: require("@/assets/colleges/universityofwashingtonseattle.jpg"),
          name: "University of Washington"
        },
        // 21
        {
          src: require("@/assets/colleges/fordham.jpg"),
          name: "Fordham"
        },
        // 22
        {
          src: require("@/assets/colleges/universityofalabama.jpg"),
          name: "University of Alabama"
        },
        // 23
        {
          src: require("@/assets/colleges/universityofsandiego.jpg"),
          name: "University of San Diego"
        },
        // 24
        {
          src: require("@/assets/colleges/georgiatech.jpg"),
          name: "Georgia Tech"
        },
        // 25
        {
          src: require("@/assets/colleges/utaustin.jpg"),
          name: "UT Austin"
        },
        // 26
        {
          src: require("@/assets/colleges/stmaryscollege.jpg"),
          name: "St. Mary's College"
        },
        // 27
        {
          src: require("@/assets/colleges/usf.jpg"),
          name: "University of San Francisco"
        },
        // 28
        {
          src: require("@/assets/colleges/universityofthepacific.jpg"),
          name: "University of the Pacific"
        },
        // 29
        {
          src: require("@/assets/colleges/csulb.jpg"),
          name: "CSULB"
        },
        // 30
        {
          src: require("@/assets/colleges/csuf.jpg"),
          name: "CSUF"
        },
        // 31
        {
          src: require("@/assets/colleges/universityofhouston.jpg"),
          name: "University of Houston"
        },
        // 32
        {
          src: require("@/assets/colleges/texasam.jpg"),
          name: "Texas A&M"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
// .el-carousel__item:nth-child(2n) {
//   background-color: #99a9bf;
// }

// .el-carousel__item:nth-child(2n + 1) {
//   background-color: #d3dce6;
// }

::v-deep button.el-carousel__arrow {
  outline: 0px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 100px;
  margin: 0;
}

::v-deep .el-carousel__button {
  height: 14px;
  width: 14px;
  border-radius: 14px;
  outline: none;
  background-color: #42a16a;
}

@media screen and (max-width: 768px) {
  ::v-deep .el-carousel__indicators {
    display: none;
  }
}
</style>
