var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"teachers-list"},_vm._l((_vm.instructors),function(instructor,index){return _c('div',{key:index,staticClass:"default-card",style:(_vm.setBackground(instructor.photo_url)),on:{"click":function($event){return _vm.openDialog(instructor)}}},[_c('div',{staticClass:"content"},[_c('p',{staticClass:"collegeName"},[_vm._v(_vm._s(_vm.helper.displayI18nText(_vm.$i18n.locale, instructor.college)))]),_c('div',{staticClass:"m-0"},[_vm._v(_vm._s(instructor.first_name))]),_c('el-button',{staticClass:"card-btn",on:{"click":function($event){return _vm.openDialog(instructor)}}},[_vm._v("Click to read biography")])],1),(_vm.isColorHex(instructor.photo_url))?_c('div',{staticStyle:{"color":"#ffffff","font-size":"240px","margin-top":"-160px"}},[_vm._v(" "+_vm._s(instructor.first_name[0])+" ")]):_vm._e()])}),0),(_vm.currentSelect !== null)?_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"width":"70%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticStyle:{"min-height":"360px"}},[(_vm.isColorHex(_vm.currentSelect.photo_url))?_c('div',{staticClass:"teacher-random-color-container",style:(`background: ${_vm.currentSelect.photo_url}`)},[_vm._v(" "+_vm._s(_vm.currentSelect.display_name[0])+" ")]):_c('div',{staticClass:"teacher-image-container",style:(`background-image: url(${_vm.currentSelect.photo_url});`)}),_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.currentSelect.display_name)+" ")]),_c('div',[_c('b',[_vm._v("School:")]),_vm._v(" "+_vm._s(_vm.helper.displayI18nText(_vm.$i18n.locale, _vm.currentSelect.college))+" ")]),(_vm.helper.displayI18nText(_vm.$i18n.locale, _vm.currentSelect.college_major))?_c('div',[_c('b',[_vm._v("Major:")]),_vm._v(" "+_vm._s(_vm.helper.displayI18nText(_vm.$i18n.locale, _vm.currentSelect.college_major))+" ")]):_vm._e(),(
            _vm.currentSelect.act_test != null &&
              _vm.currentSelect.act_test.score != 0
          )?_c('div',[_c('b',[_vm._v("ACT:")]),_vm._v(" "+_vm._s(Math.floor(( _vm.currentSelect.act_test.english_score + _vm.currentSelect.act_test.math_score + _vm.currentSelect.act_test.reading_score + _vm.currentSelect.act_test.science_score) / 4))+" (English "+_vm._s(_vm.currentSelect.act_test.english_score)+", Math "+_vm._s(_vm.currentSelect.act_test.math_score)+", Reading "+_vm._s(_vm.currentSelect.act_test.reading_score)+", Science "+_vm._s(_vm.currentSelect.act_test.science_score)+") ")]):_vm._e(),(
            _vm.currentSelect.sat_test != null &&
              _vm.currentSelect.sat_test.score != 0
          )?_c('div',[_c('b',[_vm._v("SAT:")]),_vm._v(" "+_vm._s(_vm.currentSelect.sat_test.english_score + _vm.currentSelect.sat_test.math_score)+" (English "+_vm._s(_vm.currentSelect.sat_test.english_score)+", Math "+_vm._s(_vm.currentSelect.sat_test.math_score)+") ")]):_vm._e(),(
            _vm.currentSelect.toefl_test != null &&
              _vm.currentSelect.toefl_test.score != 0
          )?_c('div',[_c('b',[_vm._v("TOEFL:")]),_vm._v(" "+_vm._s(_vm.currentSelect.toefl_test.reading_score + _vm.currentSelect.toefl_test.listening_score + _vm.currentSelect.toefl_test.speaking_score + _vm.currentSelect.toefl_test.writing_score)+" (Reading "+_vm._s(_vm.currentSelect.toefl_test.reading_score)+", Listening "+_vm._s(_vm.currentSelect.toefl_test.listening_score)+", Speaking "+_vm._s(_vm.currentSelect.toefl_test.speaking_score)+", Writing "+_vm._s(_vm.currentSelect.toefl_test.writing_score)+") ")]):_vm._e(),(
            _vm.currentSelect.ielts_test != null &&
              Number(_vm.currentSelect.ielts_test.score) != 0
          )?_c('div',[_c('b',[_vm._v("IELTS:")]),_vm._v(" "+_vm._s(Number(_vm.currentSelect.ielts_test.score))+" (Reading "+_vm._s(Number(_vm.currentSelect.ielts_test.reading_score))+", Listening "+_vm._s(Number(_vm.currentSelect.ielts_test.listening_score))+", Speaking "+_vm._s(Number(_vm.currentSelect.ielts_test.speaking_score))+", Writing "+_vm._s(Number(_vm.currentSelect.ielts_test.writing_score))+") ")]):_vm._e(),(_vm.currentSelect.ap_test.length !== 0)?_c('div',[_c('b',[_vm._v("AP:")]),_c('span',[_vm._v(" "+_vm._s(this.formatTestScoresText("ap", _vm.currentSelect.ap_test))+" ")])]):_vm._e(),(_vm.currentSelect.ib_test.length !== 0)?_c('div',[_c('b',[_vm._v("IB:")]),_c('span',[_vm._v(" "+_vm._s(this.formatTestScoresText("ib", _vm.currentSelect.ib_test))+" ")])]):_vm._e(),(_vm.currentSelect.other_test_scores)?_c('div',[_c('b',[_vm._v("Other tests:")]),_vm._v(" "+_vm._s(_vm.currentSelect.other_test_scores)+" ")]):_vm._e(),((_vm.currentSelect.showSubjects || []).length > 0)?_c('div',[_c('b',[_vm._v("Subjects to teach:")]),_vm._l((_vm.currentSelect.showSubjects),function(subject,index){return _c('el-tag',{key:index,staticStyle:{"margin":"5px"},attrs:{"type":"success","effect":"dark","size":"small"}},[_vm._v(_vm._s(subject))])}),_vm._v(" "+_vm._s(_vm.subjectsToTeach)+" ")],2):_vm._e(),_c('br'),_c('div',[_vm._v(" "+_vm._s(_vm.currentSelect.showBio)+" ")]),_c('br')])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }