<template>
  <div class="main">
    <h1 class="pb-4">Instructors</h1>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane :label="$t('instructors.overview')" name="first">
        <div>
          <el-row>
            <el-col :md="16" class="pr-3">
              <h3 class="pt-2 pb-2">{{ $t("instructors.overview") }}</h3>
              <p>
                <span v-html="$t('instructors.p1-1')"></span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('instructors.card-3-1-q')"
                  placement="top"
                >
                  <span style="cursor: pointer;">
                    <i class="fas fa-question-circle" />
                  </span>
                </el-tooltip>
                <span style="cursor: pointer;">{{ $t("instructors.p1-1-1") }}</span>
              </p>
              <p>{{ $t("instructors.p1-2") }}</p>
              <el-row>
                <el-col
                  :sm="8"
                  v-for="(elem, index) in overviewList1"
                  :key="index"
                >
                  <el-card class="m-2 card">
                    <p class="card-title">{{ $t(elem.title) }}</p>
                    <span class="card-number">{{ elem.number }}</span>
                  </el-card>
                </el-col>
                <el-col :sm="8" :key="index">
                  <el-card class="m-2 card">
                    <p class="card-title">
                      <span>{{ $t("instructors.card-3") }}</span>
                      <span>{{ $t("instructors.card-3-1") }} </span>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="$t('instructors.card-3-1-q')"
                        placement="top"
                      >
                        <span style="cursor: pointer;">
                          <i class="fas fa-question-circle" />
                        </span>
                      </el-tooltip>
                    </p>
                    <span class="card-number">95%</span>
                  </el-card>
                </el-col>
              </el-row>
              <el-row>
                <el-col
                  :sm="8"
                  v-for="(elem, index) in overviewList2"
                  :key="index"
                >
                  <el-card class="m-2 card">
                    <p class="card-title">{{ $t(elem.title) }}</p>
                    <span class="card-number">{{ elem.number }}</span>
                  </el-card>
                </el-col>
              </el-row>

              <hr />
              <div>
                <h3 class="pb-2">{{ $t("instructors.title-2") }}</h3>
                <p>{{ $t("instructors.p2-1") }}</p>
              </div>

              <!-- read more start -->
              <div id="firstCollapse">
                <div class="collapse" id="collapseBody1">
                  <p>{{ $t("instructors.p2-2") }}</p>
                  <p>{{ $t("instructors.p2-3") }}</p>
                  <p style="text-align:right" class="pb-3">
                    {{ $t("instructors.p2-4") }}
                  </p>
                  <p>{{ $t("instructors.p2-5") }}</p>
                </div>
                <a
                  class="collapsed"
                  data-toggle="collapse"
                  href="#collapseBody1"
                  aria-expanded="false"
                  aria-controls="collapseBody1"
                ></a>
              </div>
              <!-- read more end -->
              <hr />
              <div>
                <h3 class="pb-2">{{ $t("instructors.title-3") }}</h3>
                <p v-html="$t('instructors.p3-1')"></p>
                <!-- read more start -->
                <div id="secondCollapse">
                  <div class="collapse" id="collapseBody2">
                    <p v-html="$t('instructors.p3-2')"></p>
                    <p v-html="$t('instructors.p3-3')"></p>
                    <p v-html="$t('instructors.p3-4')"></p>
                    <p v-html="$t('instructors.p3-5')"></p>
                    <p v-html="$t('instructors.p3-6')"></p>
                  </div>
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    href="#collapseBody2"
                    aria-expanded="false"
                    aria-controls="collapseBody2"
                  ></a>
                </div>
                <!-- read more end -->
              </div>
              <hr />
              <div class="pb-4">
                <h4 class="pb-2">{{ $t("instructors.title-4") }}</h4>
                <p v-html="$t('instructors.p4-1')"></p>
              </div>

              <Badge />

              <hr />
              <div class="pb-5">
                <h4 class="pb-2">{{ $t("instructors.title-5") }}</h4>
                <p v-html="$t('instructors.p5-1')"></p>
                <p style="text-align:right" v-html="$t('instructors.p5-2')"></p>
                <!-- read more start -->
                <div id="thirdCollapse">
                  <div class="collapse" id="collapseBody3">
                    <p v-html="$t('instructors.p5-3')"></p>
                  </div>
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    href="#collapseBody3"
                    aria-expanded="false"
                    aria-controls="collapseBody3"
                  ></a>
                </div>
                <!-- read more end -->
              </div>
            </el-col>
            <el-col :md="8" class="pl-3 dynamicSlideCol">
              <h4 class="pb-3">
                {{ $t("instructors.studentSayAboutTeacher") }}
              </h4>
              <hooper
                style="height: 2040px"
                :vertical="true"
                :itemsToShow="5"
                :infiniteScroll="true"
                :autoPlay="true"
                :playSpeed="4000"
                :mouseDrag="false"
                :touchDrag="false"
                :wheelControl="false"
                :keysControl="false"
              >
                <Slide
                  class="feedback-slide"
                  v-for="(feedback, index) in feedbackList"
                  :key="index"
                >
                  <el-card>
                    <div class="feedbackContent">
                      <div class="text-center">
                        <el-image
                            v-show="feedback.feedback_src"
                            style="height: 200px;"
                            :src="feedback.feedback_src"
                            :preview-src-list="[feedback.feedback_src]"
                          >
                        </el-image>
                      </div>
                      <p v-html="feedback.desc"></p>
                    </div>
                    <br />
                    <div class="feedbackAuthor">
                      <span v-if="feedback.author">
                        {{ `-- ${feedback.author}` }}
                      </span>
                    </div>
                  </el-card>
                </Slide>
              </hooper>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('instructors.instructors')" name="second">
        <Instructors />
      </el-tab-pane>
      <el-tab-pane :label="$t('instructors.faq')" name="third" v-if="false">
        <FAQ />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import FAQ from "@/components/instructors/Faq";
import Badge from "@/components/instructors/Badge";
import Instructors from "@/components/instructors/Instructors";

// 加入 hooper
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";

export default {
  metaInfo() {
    return {
      title: "Instructors - Ivy-Way Academy"
    };
  },
  components: {
    FAQ,
    Badge,
    Instructors,
    Hooper,
    Slide
  },
  data() {
    return {
      activeName: "first",
      overviewList1: [
        {
          title: "instructors.card-1",
          number: "100%"
        },
        {
          title: "instructors.card-2",
          number: "100%"
        }
      ],
      overviewList2: [
        {
          title: "instructors.card-4",
          number: "373 Points"
        },
        {
          title: "instructors.card-5",
          number: "4.49/5"
        },
        {
          title: "instructors.card-6",
          number: "99%"
        }
      ],
      feedbackList: []
    };
  },
  computed: {
    ...mapGetters("stories", ["getCurrentStories"])
  },
  watch: {
    getCurrentStories(newVal) {
      const len = newVal.data.length;
      for (let i = 0; i < len; i++) {
        if (
          newVal.data[i].feedback.cn &&
          newVal.data[i].feedback.cn.length > 30
        ) {
          this.feedbackList.push({
            desc: newVal.data[i].feedback.cn,
            author: newVal.data[i].name,
            feedback_src: newVal.data[i].feedback_src
          });
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch("stories/getStories");
    this.$store.dispatch("stories/getStories", 2);
    this.$store.dispatch("stories/getStories", 3);
    this.$store.dispatch("stories/getStories", 4);
    this.$store.dispatch("stories/getStories", 5);
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.main {
  margin: 30px;
  // font-family: "Source Sans Pro", sans-serif;
}

::v-deep .el-card__body {
  padding: 5px;
}

.card {
  height: 100px;
  background-color: #eeeeee;
}

.card-title {
  font-size: 12px;
}

.card-number {
  font-size: 20px;
  font-weight: 700;
  color: #42a16a;
  position: absolute;
  bottom: 10%;
}

@media screen and (max-width: 992px) {
  .card {
    height: 90px;
  }
}

@media screen and (max-width: 992px) {
  .card {
    height: 80px;
  }
}

// #firstCollapse p.collapse:not(.show) {
//   height: 42px !important;
//   overflow: hidden;

//   display: -webkit-box;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
// }

// #firstCollapse p.collapsing {
//   min-height: 42px !important;
// }

#firstCollapse a.collapsed:after {
  content: "+ Read More";
  font-weight: 600;
  color: #42a16a;
}

#firstCollapse a:not(.collapsed):after {
  content: "- Read Less";
  font-weight: 600;
  color: #42a16a;
}

#secondCollapse a.collapsed:after {
  content: "+ Read More";
  font-weight: 600;
  color: #42a16a;
}

#secondCollapse a:not(.collapsed):after {
  content: "- Read Less";
  font-weight: 600;
  color: #42a16a;
}

#thirdCollapse a.collapsed:after {
  content: "+ Read More";
  font-weight: 600;
  color: #42a16a;
}

#thirdCollapse a:not(.collapsed):after {
  content: "- Read Less";
  font-weight: 600;
  color: #42a16a;
}

// @media screen and (min-width: 992px) {
//   .dynamicSlideCol {
//     position: fixed;
//     right: 100px;
//     top: 50%;
//     width: 25%;
//   }
// }

// carousel/slide
::v-deep .hooper {
  outline: none;
}

.feedbackContent {
  color: #42a16a;
  font-size: 16px;
  font-weight: 400;
  height: 285px;
  letter-spacing: 1.5px;
  overflow: hidden;
  padding: 10px 10px 0px 10px;
}

.feedbackAuthor {
  color: #42a16a;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.5px;
  height: 48px;
  display: flex;
  justify-content: flex-end;
  padding: 0px 10px 10px 10px;
}

::v-deep .is-clone {
  color: #42a16a;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 1.5px;
  padding: 10px 0px;
}
@media screen and (max-width: 768px) {
  .main {
    width: 100%;
    padding: 20px 15px 0;
    margin: 0;
  }
}
</style>
