<template>
  <div class="m-4">
    <h4 class="pb-2">FAQ</h4>
    <el-collapse
      v-model="activeNames"
      style="text-align:left;"
      v-for="(faq, index) in getInstructorsFaq.instructor_faqs"
      :key="index"
    >
      <el-collapse-item :name="index">
        <template slot="title">
          <span
            class="collapseTitle"
            v-html="helper.displayI18nText($i18n.locale, faq.question)"
          ></span>
        </template>
        <p v-html="helper.displayI18nText($i18n.locale, faq.explanation)"></p>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { helper } from "@ivy-way/material";

export default {
  data() {
    return {
      activeNames: ""
    };
  },
  computed: {
    ...mapGetters("instructors", ["getInstructorsFaq"]),
    helper() {
      return helper;
    }
  },
  async created() {
    // get Faq list from API
    await this.$store.dispatch("instructors/getInstructorsFaq");
  }
};
</script>

<style scoped>
.tableFont {
  font-size: 12px;
}
a {
  color: #42a16a;
}
.collapseTitle {
  font-weight: bold;
  font-size: 16px;
  color: #42a16a;
  padding-top: 10px;
  padding-bottom: 10px;
}
::v-deep .el-collapse-item__header {
  line-height: 200%;
  height: auto;
}
::v-deep .el-collapse-item__header:hover {
  opacity: 0.6;
}
</style>
