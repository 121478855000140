<template>
  <div>
    <div class="teachers-list">
      <div
        v-for="(instructor, index) in instructors"
        :key="index"
        class="default-card"
        :style="setBackground(instructor.photo_url)"
        @click="openDialog(instructor)"
      >
        <div class="content">
          <p class="collegeName">{{ helper.displayI18nText($i18n.locale, instructor.college) }}</p>
          <div class="m-0">{{ instructor.first_name }}</div>
          <el-button class="card-btn" @click="openDialog(instructor)"
            >Click to read biography</el-button
          >
        </div>
        <div
          v-if="isColorHex(instructor.photo_url)"
          style="color: #ffffff; font-size: 240px; margin-top: -160px;"
        >
          {{ instructor.first_name[0] }}
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="70%"
      v-if="currentSelect !== null"
    >
      <div style="min-height: 360px">
        <div
          v-if="isColorHex(currentSelect.photo_url)"
          class="teacher-random-color-container"
          :style="`background: ${currentSelect.photo_url}`"
        >
          {{ currentSelect.display_name[0] }}
        </div>
        <div
          v-else
          class="teacher-image-container"
          :style="`background-image: url(${currentSelect.photo_url});`"
        />
        <div>
          <h2>
            {{ currentSelect.display_name }}
          </h2>
          <div>
            <b>School:</b>
            {{ helper.displayI18nText($i18n.locale, currentSelect.college) }}
          </div>
          <div v-if="helper.displayI18nText($i18n.locale, currentSelect.college_major)">
            <b>Major:</b>
            {{ helper.displayI18nText($i18n.locale, currentSelect.college_major) }}
          </div>
          <div
            v-if="
              currentSelect.act_test != null &&
                currentSelect.act_test.score != 0
            "
          >
            <b>ACT:</b>
            {{
              Math.floor((
                currentSelect.act_test.english_score +
                currentSelect.act_test.math_score +
                currentSelect.act_test.reading_score +
                currentSelect.act_test.science_score) / 4)
            }}
            (English {{ currentSelect.act_test.english_score }}, Math
            {{ currentSelect.act_test.math_score }}, Reading
            {{ currentSelect.act_test.reading_score }}, Science
            {{ currentSelect.act_test.science_score }})
          </div>
          <div
            v-if="
              currentSelect.sat_test != null &&
                currentSelect.sat_test.score != 0
            "
          >
            <b>SAT:</b>
            {{
              currentSelect.sat_test.english_score +
                currentSelect.sat_test.math_score
            }}
            (English {{ currentSelect.sat_test.english_score }}, Math
            {{ currentSelect.sat_test.math_score }})
          </div>
          <div
            v-if="
              currentSelect.toefl_test != null &&
                currentSelect.toefl_test.score != 0
            "
          >
            <b>TOEFL:</b>
            {{
              currentSelect.toefl_test.reading_score +
                currentSelect.toefl_test.listening_score +
                currentSelect.toefl_test.speaking_score +
                currentSelect.toefl_test.writing_score
            }}
            (Reading {{ currentSelect.toefl_test.reading_score }}, Listening
            {{ currentSelect.toefl_test.listening_score }}, Speaking
            {{ currentSelect.toefl_test.speaking_score }}, Writing
            {{ currentSelect.toefl_test.writing_score }})
          </div>
          <div
            v-if="
              currentSelect.ielts_test != null &&
                Number(currentSelect.ielts_test.score) != 0
            "
          >
            <b>IELTS:</b>
            {{ Number(currentSelect.ielts_test.score) }}
            (Reading {{ Number(currentSelect.ielts_test.reading_score) }}, Listening
            {{ Number(currentSelect.ielts_test.listening_score) }}, Speaking
            {{ Number(currentSelect.ielts_test.speaking_score) }}, Writing
            {{ Number(currentSelect.ielts_test.writing_score) }})
          </div>
          <div v-if="currentSelect.ap_test.length !== 0">
            <b>AP:</b>
            <span>
              {{ this.formatTestScoresText("ap", currentSelect.ap_test) }}
            </span>
          </div>
          <div v-if="currentSelect.ib_test.length !== 0">
            <b>IB:</b>
            <span>
              {{ this.formatTestScoresText("ib", currentSelect.ib_test) }}
            </span>
          </div>
          <div v-if="currentSelect.other_test_scores">
            <b>Other tests:</b>
            {{ currentSelect.other_test_scores }}
          </div>
          <div v-if="(currentSelect.showSubjects || []).length > 0">
            <b>Subjects to teach:</b>
            <el-tag
              style="margin:5px"
              type="success"
              effect="dark"
              size="small"
              v-for="(subject, index) in currentSelect.showSubjects"
              :key="index"
              >{{ subject }}</el-tag
            >
            {{ subjectsToTeach }}
          </div>
          <br />
          <div>
            {{ currentSelect.showBio }}
          </div>
          <br />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import subjectsData from "@/data/subjects.json";

import { helper } from "@ivy-way/material";
import profileTest from "@/mixins/profileTest";
export default {
  mixins: [profileTest],
  data() {
    return {
      instructors: [],
      dialogVisible: false,
      currentSelect: null,
      subjectsToTeach: ""
    };
  },
  computed: {
    ...mapGetters("instructors", ["getInstructorsForBio"]),
    ...mapState("user", ["lang"]),
    subjectsData() {
      return subjectsData;
    },
    helper() {
      return helper;
    }
  },
  watch: {
    getInstructorsForBio(newVal) {
      const getRandomColor = () => {
        const hexColor = Math.floor(Math.random() * 1000000);
        return `#${String(hexColor).padStart(6, "0")}`;
      };
      this.instructors = newVal.users.map(instructor => ({
        ...instructor,
        photo_url: instructor.photo_url ? instructor.photo_url : getRandomColor()
      }));
    }
  },
  async mounted() {
    await this.$store.dispatch("instructors/getInstructorsForBio");
  },
  methods: {
    setBackgroundLocal(bgUrl) {
      return {
        backgroundImage: "url(" + require(bgUrl) + ")"
      };
    },
    setBackground(bgUrl) {
      if (this.isColorHex(bgUrl)) {
        return {
          background: bgUrl
          
        };
      } else {
        return {
          backgroundImage: `url(${bgUrl})`
        };
      }
    },
    openDialog(instructor) {
      this.dialogVisible = true;
      this.currentSelect = instructor;

      // 還要把 subjectsToTeach 的內容翻譯成顯示內容
      if (this.currentSelect.subjects !== null) {
        this.currentSelect.showSubjects = this.getLabelOfSubjects(
          this.currentSelect.subjects
        );
      }
      if (this.currentSelect.bio[this.$i18n.locale] != undefined) {
        this.currentSelect.showBio = this.currentSelect.bio[this.$i18n.locale];
      } else {
        this.currentSelect.showBio = "";
      }
    },
    isColorHex(value) {
      return value[0] === "#" && value.length === 7;
    },
    formatTestScoresText(testType, tests) {
      const testSubjectWithScore = tests.map((test) => (
        `${this.getTestSubject(testType, test.subject)} (${test.score})`
      ));
      
      return this.helper.displayMultipleItems(testSubjectWithScore);
    },
    getTestSubject(testType, subjectValue) {
      let testSubjects = [];
      switch (testType) {
        case "ib":
        case "ap":
          testSubjects = this.subjectsData.find(
            ({ label }) => label === testType.toUpperCase()
          ).options;
          break;
        default:
          // do nothing
      }
      return testSubjects.find(({ value }) => value === subjectValue).label;
    },
    showMandarin(val) {
      if (val === 0) {
        return "暫時不會。";
      } else if (val === 1) {
        return "可以交流。";
      } else if (val === 2) {
        return "可以教學。";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.filterTitle {
  font-weight: bold;
}

.filterBtn {
  opacity: 0.9;
}

.display {
  width: 25%;
}

.teachers-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.teacher-image {
  height: 300px;
  width: 300px;
  border: 1px solid #eee;
  border-radius: 10px;
}
.default-card {
  // background-image: url(../../assets/instructor/AlbertShin.jpg);
  border-radius: 10px;
  border: 1px solid #eee;
  background-size: cover;
  background-repeat: no-repeat;
  height: 360px;
  width: 360px;
  margin: 10px 0;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
}
.content {
  position: relative;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  height: 150px;
  width: 100%;
  color: white;
  // transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.3s linear;
  transform: translateY(280px);

  > * + * {
    margin-top: 1rem;
  }
}

.collegeName {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0px;
}

.card-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -22%);
  // bottom: 25px;
}

.teacher-image-container {
  width: 320px;
  height: 320px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  float: right;
  margin: 0px 0px 20px 20px;
}

.teacher-random-color-container {
  width: 320px;
  height: 320px;
  float: right;
  margin: 0px 0px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 240px;
  color: #ffffff;
}

@media (hover: hover) {
  .default-card:hover {
    .content {
      transform: translateY(210px);
      transition: transform 0.5s ease-out;
    }
  }
}

// 以下為調整 el-col card image 客製化 rwd
@media screen and (max-width: 1200px) {
  .display {
    width: 33%;
  }
}

@media screen and (max-width: 1050px) {
  .display {
    width: 33%;
  }
}

@media screen and (max-width: 830px) {
  .display {
    width: 50%;
  }
  .default-card {
    background-position: center;
  }
}

@media screen and (max-width: 600px) {
  .display {
    width: 100%;
  }
  .default-card {
    background-position: center;
  }
}

@media screen and (max-width: 480px) {
  .default-card {
    background-position: top;
  }
  
  .teacher-image-container {
    width: 100%;
    height: 200px;
    padding: 0px 0px 20px 20px;
  }
}

.el-button {
  outline: none;
}
</style>
